import React from "react";
import PageWrapper from "../components/PageWrapper";

const Support = () => {
  return (
    <PageWrapper
      themeConfig={{
        footerStyle: "style3",
        headerClassName: "d-none",
      }}
    >
      <div className="w-100">
        <iframe
          id={"iframe-support"}
          className=""
          src="https://app.startinfinity.com/form/b7d9f500-0c21-4c29-aca2-06ace6b228bd"
          width="100%"
          height="700"
        ></iframe>
      </div>
    </PageWrapper>
  );
};

export default Support;
